import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../../components/layout';
export const _frontmatter = {
  "title": "Controls"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Controls`}</h1>
    <p>{`Components can use the controls API to expose form elements in the Blocks
editor for manipulating props. It adheres to
`}<a parentName="p" {...{
        "href": "https://www.framer.com/api/property-controls/"
      }}>{`Framer's Property Controls`}</a>{`
in order to ensure interoperability.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Controls } from 'blocks-ui'
`}</code></pre>
    <h2>{`Types`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`String`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Boolean`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true/false property`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Enum`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Collection of possible values for a property`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`enum`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Number`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Single number property`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String property`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h5>{`Future considerations`}</h5>
    <ul>
      <li parentName="ul">{`Components as props`}</li>
      <li parentName="ul">{`Objects as props (with particular shapes and/or nesting)`}</li>
      <li parentName="ul">{`Arrays as props`}</li>
      <li parentName="ul">{`Babel plugin to remove the controls for production builds`}</li>
    </ul>
    <h3>{`Boolean`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Controls.Boolean
`}</code></pre>
    <p>{`You can use the boolean control type to expose a checkbox that turns a property
on or off.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label for the checkbox`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`defaultValue`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The checkbox's default value`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Controls } from 'blocks-ui'

export const Component = ({ isTomato, ...props }) => (
  <h1
    {...props}
    style={{
      color: isTomato ? 'tomato' : 'inherit'
    }}
  />
)

Controls.applyPropertyControls(Component, {
  isTomato: {
    type: Controls.Boolean,
    title: 'Tomato'
  }
})
`}</code></pre>
    <h3>{`Enum`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Controls.Enum
`}</code></pre>
    <p>{`You can use the enum control type to expose a select that contains all
provided possible values.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label the select`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`defaultValue`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The select's default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The values for the options`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`optionTitles`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode>{` values`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Titles for the given options`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Controls } from 'blocks-ui'

export const Component = ({ color, ...props }) => (
  <h1
    {...props}
    style={{ color }}
  />
)

Controls.applyPropertyControls(Component, {
  color: {
    type: Controls.Enum,
    title: 'Color',
    defaultValue: 'tomato',
    options: ['tomato', 'purple', 'black'],
    optionTitles: ['Tomato', 'Purple', 'Black']
  }
})
`}</code></pre>
    <h3>{`Number`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Controls.Number
`}</code></pre>
    <p>{`You can use the enum control type to expose a select that contains all
provided possible values.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label for the prop`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`defaultValue`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The default value for the number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`min`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The minimum value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`max`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The maximum value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`step`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The step value for the slider`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unit which is appended to the number (returned as a string if provided)`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Controls } from 'blocks-ui'

export const Component = ({ lineHeight, ...props }) => (
  <h1
    {...props}
    style={{ lineHeight }}
  />
)

Controls.applyPropertyControls(Component, {
  lineHeight: {
    type: Controls.Number,
    title: 'Line Height',
    defaultValue: 1.2,
    min: 1,
    max: 2,
    step: .1
  }
})
`}</code></pre>
    <h3>{`String`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Controls.String
`}</code></pre>
    <p>{`Display a text input for a string-based prop`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label for the prop`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`defaultValue`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The default value for the string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`required`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the string is required`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`placeholder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Placeholder in the text input`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Controls } from 'blocks-ui'

export const Component = ({ text, ...props }) => (
  <h1
    {...props}
    children={text}
  />
)

Controls.applyPropertyControls(Component, {
  text: {
    type: Controls.String,
    required: true,
    placeholder: 'Enter a title...'
  }
})
`}</code></pre>
    <br /><br />
    <hr></hr>
    <br /><br />
    <p style={{
      color: '#444'
    }}>
      <em>
Note: Controls are also available as a standalone package, <a href="https://npmjs.com/package/property-controls">property-controls</a>
      </em>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      